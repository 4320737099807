import { useRequest, useTitle } from "ahooks";
import { findActivityFormByOrderNo } from "../../../../requests/user-order";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { ActionBar, Card, Skeleton, Space, Typography } from "react-vant";
import { CardBody } from "react-vant/es/card/Card";
import { OrderActivityForm } from "../../../../entity";
import { isNil } from "lodash";
import {
  getPaidDescription,
  getPayTypeDescription,
} from "../../../../utils/desctriptor";
import ContentCell from "../Components/ContentCard";
import { getMaskMobile } from "../../../../utils/util";
import SvgPhone from "@react-vant/icons/es/Phone";
import { ContentCellGroup } from "../../../../type";

const getItems = (order: OrderActivityForm): ContentCellGroup[] => [
  {
    title: "报名信息",
    children: [
      { title: "参与活动", value: order.activity_title },
      { title: "宝贝姓名", value: order.child_name },
      { title: "宝贝年龄", value: `${order.child_age}岁` },
      { title: "联系方式", value: getMaskMobile(order.mobile) },
      {
        title: "报名状态",
        value: <Typography.Text type={"success"}>报名成功</Typography.Text>,
      },
    ],
  },
  {
    title: "订单信息",
    children: [
      { title: "订单号", value: order.order_no },
      { title: "订单标题", value: order.order.brief },
      { title: "支付时间", value: order.order.paid_at },
      { title: "支付金额", value: `${order.order.total_fee}元` },
      {
        title: "支付方式",
        value: getPayTypeDescription(order.order.pay_type),
      },
      { title: "订单状态", value: getPaidDescription(order.order.paid_at) },
    ],
  },
];

const ActivityFormView = () => {
  useTitle("订单详情");
  const navigate = useNavigate();
  const { orderNo } = useParams();
  const [order, setOrder] = useState<OrderActivityForm>();
  const [items, setItems] = useState<ContentCellGroup[]>([]);
  const findRequest = useRequest(
    () => findActivityFormByOrderNo(orderNo || ""),
    {
      manual: true,
      onSuccess: (data) => {
        if (!isNil(data)) {
          setItems(getItems(data));
          setOrder(data);
        }
      },
    }
  );
  useEffect(() => {
    if (orderNo) findRequest.run();
  }, []);
  if (findRequest.loading)
    return (
      <div className="pt-1">
        <Card className="mb-2">
          <CardBody className="px-0">
            <Skeleton title row={10} />
          </CardBody>
        </Card>
      </div>
    );
  return (
    <div className="pt-1">
      <Space block direction="vertical" className="pb-32" gap={10}>
        {items.map((item) => (
          <ContentCell key={item.title} item={item} />
        ))}
      </Space>
      {order && (
        <ActionBar>
          <ActionBar.Button
            text
            className={"text-blue-600"}
            icon={<SvgPhone />}
            onClick={() =>
              (location.href = `tel://${order.order.merchant.contact_mobile}`)
            }
          >
            联系客服
          </ActionBar.Button>
          <ActionBar.Button
            type="primary"
            onClick={() => navigate(`/activity-form/${order.activity_form_id}`)}
          >
            查看活动
          </ActionBar.Button>
        </ActionBar>
      )}
    </div>
  );
};

export default ActivityFormView;
