import { Image } from "react-vant";
import { useEffect } from "react";
import { useBannerStore } from "../Store/banner";

export default function ParkingFeeBanner(): JSX.Element {
  const { banner, handleFetch } = useBannerStore();

  useEffect(() => {
    handleFetch();
  }, []);

  return (
    <Image
      height={240}
      width="100%"
      src={banner?.image_url}
      onClick={() => {
        if (banner?.click_url) window.location.href = banner.click_url;
      }}
    />
  );
}
