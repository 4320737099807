import { createStore } from "hox";
import { useState } from "react";
import { ParkingOrderBody } from "../../../../request";
import { useRequest } from "ahooks";
import { findOrderByPlateNo } from "../../../../requests/parking-fee";

export const [useParkingOrderStore, ParkingOrderStoreProvider] = createStore(
  () => {
    const [order, setOrder] = useState<ParkingOrderBody>();
    const request = useRequest(findOrderByPlateNo, {
      manual: true,
    });

    const handleFetch = async (licenseNo: string) => {
      const response = await request.runAsync(licenseNo);
      if (response.success) {
        setOrder(response.data);
        return response.data;
      }
      return undefined;
    };

    const getParkingPayAmount = (discountAmount = 0) => {
      if (!order) return 0;
      if (order.payable <= discountAmount) return 0;
      return order.payable - discountAmount;
    };

    return {
      order,
      loading: request.loading,
      handleFetch,
      getParkingPayAmount,
    };
  }
);
