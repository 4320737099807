import { useRequest, useSetState, useTitle } from "ahooks";
import { getList } from "../../../requests/user-coupon";
import { useState } from "react";
import { UserCoupon as UserCouponEntity } from "../../../entity";
import { PaginationQueryParams } from "../../../request";
import svgIcon from "../../../assets/svg";
import { Status } from "../../../utils/enum";
import { filterTabs, UserCouponFilterParams } from "./constant";

export default function UserCoupon(): JSX.Element {
  useTitle("我的优惠券");
  const { StatusEmpty } = svgIcon;
  const [filterParams] = useSetState<UserCouponFilterParams>({
    active: Status.active,
    used: Status.inActive,
    over_date: Status.inActive,
  });
  const [queryParams, setQueryParams] = useSetState<PaginationQueryParams>({
    page: 1,
    take: 8,
  });
  const [userCouponList, setUserCouponList] = useState<UserCouponEntity[]>([]);
  const [finished, setFinished] = useState(false);
  const request = useRequest(getList, {
    manual: true,
  });

  const onLoad = async (params: PaginationQueryParams) => {
    /*    const response = await request.runAsync(params);
    setUserCouponList((v) => {
      const combine = [...v, ...response.data];
      if (combine.length >= response.meta.total) {
        setFinished(true);
      }
      setQueryParams({
        page: params.page + 1,
      });
      return combine;
    });*/
  };

  const handleTabChange = async (name: string | number) => {
    const tabPane = filterTabs.find((tab) => name === tab.name);
    if (tabPane) {
      setUserCouponList([]);
      setFinished(false);
      await onLoad({ ...queryParams, ...tabPane?.value, page: 1 });
    }
  };

  return (
    <>
      {/*      <Tabs onChange={handleTabChange}>
        {filterTabs.map((tab) => (
          <TabPane key={tab.name} name={tab.name} title={tab.title} />
        ))}
      </Tabs>
      <div className="p-3">
        {userCouponList.map((coupon) => (
          <CouponContent key={coupon.id} coupon={coupon} />
        ))}
        <List
          loading={request.loading}
          finished={finished}
          onLoad={() => onLoad({ ...queryParams, ...filterParams })}
        >
          {!request.loading && userCouponList.length < 1 && (
            <Empty
              description="没有可用的优惠券~"
              image={<Image src={StatusEmpty} />}
            />
          )}
        </List>
      </div>*/}
    </>
  );
}
