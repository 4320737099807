import { FilterTabProps } from "../../../type";
import { Status } from "../../../utils/enum";

export interface UserCouponFilterParams {
  active: number;
  used: number;
  over_date: number;
}

export const filterTabs: FilterTabProps<UserCouponFilterParams>[] = [
  {
    name: "0",
    title: "最近获得",
    value: {
      active: Status.active,
      used: Status.inActive,
      over_date: Status.inActive,
    },
  },
  {
    name: "1",
    title: "过期/失效券",
    value: {
      active: Status.inActive,
      used: Status.inActive,
      over_date: Status.active,
    },
  },
  {
    name: "2",
    title: "已使用",
    value: {
      active: Status.inActive,
      used: Status.active,
      over_date: Status.inActive,
    },
  },
];
