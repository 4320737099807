import { useParkingOrderStore } from "../Store/parking-order";
import ParkingCoupon from "./ParkingCoupon";
import ParkingOrderDetail from "./ParkingOrderDetail";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAsyncEffect } from "ahooks";
import ParkingCarState from "./ParkingCarState";
import { BannerStoreProvider } from "../Store/banner";
import ParkingFeeBanner from "./ParkingOrderPayBanner";
import { Button, Skeleton, Space, Typography } from "react-vant";
import { isNil } from "lodash";
import { Paid } from "@react-vant/icons";
import { useParkingOrderPayStore } from "../Store/parking-pay";
import { useUserCouponStore } from "../Store/user-coupon";
import { ParkingPayFormValues } from "../../../../request";

const ParkingOrderCheckoutComponent = () => {
  const navigate = useNavigate();
  const { licenseNo } = useParams();
  const { order, loading, handleFetch } = useParkingOrderStore();
  const { parkingOrderPayLoading, payParkingOrder } = useParkingOrderPayStore();
  const { selectedCoupon } = useUserCouponStore();

  const handleFetchParkingOrder = async (licenseNo: string) => {
    const parkingOrder = await handleFetch(licenseNo);
    if (!parkingOrder) navigate(`/parking/fee/find/${licenseNo}/failed`);
  };

  const handlePayParkingOrder = async (licenseNo: string) => {
    let data: ParkingPayFormValues = { license_no: licenseNo };
    const userCoupon = selectedCoupon();
    if (userCoupon) {
      data = { ...data, coupon_id: userCoupon.id };
    }
    await payParkingOrder(data);
  };

  useAsyncEffect(async () => {
    if (licenseNo) {
      await handleFetchParkingOrder(licenseNo);
    }
  }, [licenseNo]);

  if (loading)
    return (
      <div className="min-h-screen bg-white overflow-hidden">
        <Skeleton title row={15} className="mb-5 pt-6" />
      </div>
    );

  if (isNil(order)) return <></>;

  return (
    <>
      <BannerStoreProvider>
        <ParkingFeeBanner />
      </BannerStoreProvider>

      <ParkingCarState />

      <ParkingCoupon />

      <ParkingOrderDetail />

      {order.payable > 0 && (
        <div className="fixed w-full bottom-8 h-12 text-center">
          <Button
            loading={parkingOrderPayLoading}
            disabled={parkingOrderPayLoading}
            type="primary"
            className="h-full w-2/3 rounded-lg"
            onClick={() => handlePayParkingOrder(licenseNo || "")}
          >
            <Space block justify="center" align="center">
              <Paid fontSize={20} />
              <Typography.Text className="text-white">立即支付</Typography.Text>
            </Space>
          </Button>
        </div>
      )}
    </>
  );
};

export default ParkingOrderCheckoutComponent;
