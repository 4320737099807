import { useRequest, useTitle } from "ahooks";
import { findParkingFeeByOrderNo } from "../../../../requests/user-order";
import { useNavigate, useParams } from "react-router-dom";
import { FC, useEffect, useState } from "react";
import { ActionBar, Card, Skeleton, Space } from "react-vant";
import { CardBody } from "react-vant/es/card/Card";
import { OrderParkingFee } from "../../../../entity";
import { isNil } from "lodash";
import {
  getPaidDescription,
  getPayTypeDescription,
} from "../../../../utils/desctriptor";
import ContentCell from "../Components/ContentCard";
import { ContentCellGroup } from "../../../../type";

const getItems = (order: OrderParkingFee): ContentCellGroup[] => [
  {
    title: "临停信息",
    children: [
      { title: "订单号", value: order.order_no },
      { title: "车牌号", value: order.plate_no },
      { title: "入场时间", value: order.entry_time },
      { title: "支付时间", value: order.order.paid_at },
      { title: "应付金额", value: `${order.order.receipt_fee}元` },
      {
        title: "减免",
        value: `${order.derate_fee / 100}元`,
      },
      { title: "实付金额", value: `${order.order.total_fee}元` },
      {
        title: "支付方式",
        value: getPayTypeDescription(order.order.pay_type),
      },
      {
        title: "订单状态",
        value: getPaidDescription(order.order.paid_at),
      },
    ],
  },
];

const ParkingFeeView: FC = () => {
  useTitle("订单详情");
  const { orderNo } = useParams();
  const navigate = useNavigate();
  const [order, setOrder] = useState<OrderParkingFee>();
  const [items, setItems] = useState<ContentCellGroup[]>([]);
  const findRequest = useRequest(() => findParkingFeeByOrderNo(orderNo || ""), {
    manual: true,
    onSuccess: (data) => {
      if (!isNil(data)) {
        setOrder(data);
        setItems(getItems(data));
      }
    },
  });
  useEffect(() => {
    if (orderNo) findRequest.run();
  }, []);
  if (findRequest.loading)
    return (
      <div className="pt-1">
        <Card className="mb-2">
          <CardBody className="px-0">
            <Skeleton title row={15} />
          </CardBody>
        </Card>
      </div>
    );
  return (
    <div className="pt-1">
      <Space block direction="vertical" className="pb-32" gap={10}>
        {items.map((item) => (
          <ContentCell key={item.title} item={item} />
        ))}
      </Space>
      <ActionBar>
        <ActionBar.Button
          type="primary"
          onClick={() => navigate(`/parking/fee/${order?.plate_no}`)}
        >
          去缴费
        </ActionBar.Button>
      </ActionBar>
    </div>
  );
};

export default ParkingFeeView;
