import request from "../utils/request";
import { DataResponse } from "../request";
import { UserCoupon } from "../entity";

const ROUTE = "user/coupons";

export function getList(): Promise<UserCoupon[]> {
  return request().get(ROUTE);
}

export const receiveCoupon = (
  couponReleaseId: number
): Promise<DataResponse<{ remain_count: number }>> => {
  return request().post(`${ROUTE}/get-release/${couponReleaseId}`);
};

export const receiveCouponCount = (
  couponReleaseId: number
): Promise<number> => {
  return request().get(`${ROUTE}/has-release/${couponReleaseId}`);
};
