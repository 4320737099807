import { Space, Typography } from "react-vant";
import { Merchant } from "../../entity";
import Skeleton from "react-loading-skeleton/dist";

export default function CheckstandHeader({
  loading,
  merchant,
}: {
  loading: boolean;
  merchant: Merchant | undefined;
}): JSX.Element {
  return (
    <Space direction={"vertical"} block className={"text-center pb-10"}>
      {loading ? (
        <Skeleton width={50} height={50} />
      ) : (
        <img
          style={{ height: 50 }}
          src={merchant?.logo_url}
          className="mb-1 rounded"
          alt={""}
        />
      )}
      {!loading && (
        <Typography.Text strong size="lg">
          {merchant?.merchant_name}
        </Typography.Text>
      )}
    </Space>
  );
}
