import { useTitle } from "ahooks";
import "./index.css";
import { ParkingOrderStoreProvider } from "./Store/parking-order";
import ParkingOrderCheckoutComponent from "./Components/ParkingOrderCheckoutComponent";
import { UserCouponStoreProvider } from "./Store/user-coupon";
import { ParkingOrderPayStoreProvider } from "./Store/parking-pay";

export default function ParkingFeeCheckout() {
  useTitle("停车缴费");

  return (
    <div className="min-h-screen bg-white overflow-hidden">
      <ParkingOrderStoreProvider>
        <UserCouponStoreProvider>
          <ParkingOrderPayStoreProvider>
            <ParkingOrderCheckoutComponent />
          </ParkingOrderPayStoreProvider>
        </UserCouponStoreProvider>
      </ParkingOrderStoreProvider>
    </div>
  );
}
