import { createStore } from "hox";
import { useState } from "react";
import { UserCoupon } from "../../../../entity";
import { useRequest } from "ahooks";
import { getList } from "../../../../requests/user-coupon";
import dayjs from "dayjs";
import { CouponInfo } from "react-vant/es/coupon-list/PropsType";
import { useParkingOrderStore } from "./parking-order";
import { getCouponAmount } from "../../../../utils/util";

export const [useUserCouponStore, UserCouponStoreProvider] = createStore(() => {
  const { order } = useParkingOrderStore();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [userCoupons, setUserCoupons] = useState<UserCoupon[]>([]);
  const [userCouponsMap, setUserCouponsMap] = useState<CouponInfo[]>([]);
  const request = useRequest(getList, { manual: true });

  const handleFetch = async () => {
    const response = await request.runAsync();
    if (response) {
      setUserCoupons(response);
      setUserCouponsMap(
        response.map((userCoupon) => {
          return {
            id: userCoupon.id,
            name: userCoupon.coupon_title,
            condition: "无门槛",
            startAt: dayjs(userCoupon.valid_from, "YYYY-MM-DD").unix(),
            endAt: dayjs(userCoupon.valid_to, "YYYY-MM-DD").unix(),
            value: userCoupon.coupon_amount,
            unitDesc: "元",
            valueDesc: `${userCoupon.coupon_amount / 100}`,
          } as CouponInfo;
        })
      );
    }
  };

  const getCouponDiscountAmount = () => {
    if (!order) return 0;
    const coupon = selectedCoupon();
    if (coupon) {
      return getCouponAmount(coupon, order.payable);
    }
    return 0;
  };

  const selectedCoupon = () => {
    if (userCoupons) return userCoupons[selectedIndex];
    return null;
  };

  return {
    userCoupons,
    userCouponsMap,
    selectedCoupon,
    selectedIndex,
    setSelectedIndex,
    handleFetch,
    getCouponDiscountAmount,
  };
});
