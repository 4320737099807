import { FC, useEffect, useState } from "react";
import { CouponCell, CouponList, Popup } from "react-vant";
import { useUserCouponStore } from "../Store/user-coupon";

const ParkingCoupon: FC = () => {
  const [open, setOpen] = useState(false);
  const { userCouponsMap, selectedIndex, setSelectedIndex, handleFetch } =
    useUserCouponStore((store) => [store.userCoupons, store.selectedIndex]);

  useEffect(() => {
    handleFetch();
  }, []);

  return (
    <>
      <CouponCell
        coupons={userCouponsMap}
        chosenCoupon={selectedIndex}
        className={"my-10"}
        onClick={() => setOpen(true)}
      />
      <Popup
        round
        position="bottom"
        style={{ height: "90%", paddingTop: 4 }}
        visible={open}
        onClose={() => setOpen(false)}
      >
        <CouponList
          showExchangeBar={false}
          coupons={userCouponsMap}
          chosenCoupon={selectedIndex}
          onChange={(value) => {
            setSelectedIndex(value);
            setOpen(false);
          }}
          disabledCoupons={[]}
        />
      </Popup>
    </>
  );
};

export default ParkingCoupon;
