import { Button, Empty, Image, Space, Typography } from "react-vant";
import svgIcon from "../../../../assets/svg";
import { useNavigate, useParams } from "react-router-dom";

export default function ParkingOrderSearchFailed() {
  const navigate = useNavigate();
  const { licenseNo } = useParams();
  const { StatusSearchFailed } = svgIcon;

  return (
    <div className="min-h-screen bg-white overflow-hidden">
      <Space block direction="vertical">
        <Empty
          className="pt-0 pb-5"
          image={<Image src={StatusSearchFailed} height={180} width={180} />}
        />
        <Typography.Text type="secondary">
          {`抱歉，未找到 ${licenseNo} 的停车订单`}
        </Typography.Text>
        <Button
          type="primary"
          plain
          className="px-10 mt-8"
          onClick={() =>
            navigate(`/parking/fee/${licenseNo}`, { replace: true })
          }
        >
          返回查询
        </Button>
      </Space>
    </div>
  );
}
