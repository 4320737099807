import { Card, Space, Typography } from "react-vant";
import { CardBody, CardHeader } from "react-vant/es/card/Card";
import { useRequest, useTitle } from "ahooks";
import { findById } from "../../requests/merchant-project";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { MerchantProject } from "../../entity";
import MerchantProjectSubmitBar from "./Components/MerchantProjectSubmitBar";
import MerchantProjectViewHead from "./Components/MerchantProjectViewHead";
import HTMLReactParser from "html-react-parser";
import { toNumber } from "lodash";
import Placeholder from "./Components/Placeholder";
import "./Styles/index.css";

export default function MerchantProjectMain(): JSX.Element {
  useTitle("课程详情");
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [merchantProject, setMerchantProject] = useState<MerchantProject>();
  const request = useRequest(findById, {
    manual: true,
  });

  const handleFetch = async (id: number) => {
    const response = await request.runAsync(
      toNumber(searchParams.get("activity_sale_id")),
      id
    );
    if (response) setMerchantProject(response);
    else navigate("/404");
  };

  useEffect(() => {
    if (id) handleFetch(toNumber(id));
    else navigate("/404");
  }, [id]);

  if (merchantProject)
    return (
      <>
        <Space direction="vertical" block gap={10} className="pb-24">
          <MerchantProjectViewHead merchantProject={merchantProject} />

          <Card>
            <CardHeader>图文详情</CardHeader>
            <CardBody className={"special-card-project-content"}>
              {HTMLReactParser(merchantProject.content || "")}
            </CardBody>
          </Card>

          <MerchantProjectSubmitBar merchantProject={merchantProject} />
        </Space>
      </>
    );

  return <Placeholder />;
}
