import { createStore } from "hox";
import { useRequest } from "ahooks";
import { pay } from "../../../../requests/parking-fee";
import { Toast } from "react-vant";
import { ParkingPayFormValues } from "../../../../request";
import { useNavigate } from "react-router-dom";
import { isString } from "lodash";

export const [useParkingOrderPayStore, ParkingOrderPayStoreProvider] =
  createStore(() => {
    const navigate = useNavigate();
    const parkingPayRequest = useRequest(pay, {
      manual: true,
    });

    const payParkingOrder = async (values: ParkingPayFormValues) => {
      const response = await parkingPayRequest.runAsync(values);
      if (response.success) {
        const data = response.data;
        if (isString(data)) return Toast.fail(data);
        if (data.payType === "WECHAT" && data.codeUrl) {
          Toast.loading("跳转支付中");
          location.href = data.codeUrl;
        } else if (data.payType == "ZERO")
          navigate("/parking/fee/result/" + data.orderNo);
      }
    };

    return {
      parkingOrderPayLoading: parkingPayRequest.loading,
      payParkingOrder,
    };
  });
