import { Space, Typography } from "react-vant";
import { CardBody } from "react-vant/es/card/Card";
import { FC } from "react";
import { useParkingOrderStore } from "../Store/parking-order";
import { useUserCouponStore } from "../Store/user-coupon";

const ParkingOrderDetail: FC = () => {
  const { getParkingPayAmount } = useParkingOrderStore();
  const { getCouponDiscountAmount } = useUserCouponStore();

  return (
    <CardBody className="mb-10">
      <Space block direction="vertical" justify="between" gap={15}>
        <Space gap={10}>
          <Typography.Title level={4}>需缴费</Typography.Title>
          <Typography.Title level={4} type="danger">
            ¥{getParkingPayAmount(getCouponDiscountAmount()) / 100}
          </Typography.Title>
        </Space>
        <Space block justify="between" align="center">
          <Typography.Text type="secondary">停车费</Typography.Text>
          <Typography.Text type="secondary">
            ¥{getParkingPayAmount() / 100}
          </Typography.Text>
        </Space>
        <Space block justify="between" align="center">
          <Typography.Text type="secondary">优惠</Typography.Text>
          <Typography.Text type="secondary">
            ¥{getCouponDiscountAmount() / 100}
          </Typography.Text>
        </Space>
        <Space block justify="between" align="center">
          <Typography.Text type="secondary">活动抵扣</Typography.Text>
          <Typography.Text type="secondary">¥0</Typography.Text>
        </Space>
      </Space>
    </CardBody>
  );
};

export default ParkingOrderDetail;
