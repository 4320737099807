import { createStore } from "hox";
import { useState } from "react";
import { useRequest } from "ahooks";
import { getParkingStickBanner } from "../../../../requests/activity-banner";
import { ActivityBanner } from "../../../../entity";

export const [useBannerStore, BannerStoreProvider] = createStore(() => {
  const [banner, setBanner] = useState<ActivityBanner>();
  const request = useRequest(getParkingStickBanner, {
    manual: true,
  });

  const handleFetch = async () => {
    setBanner(await request.runAsync());
  };

  return {
    banner,
    handleFetch,
  };
});
