import { Empty, Image, List, Tabbar, Typography } from "react-vant";
import { useState } from "react";
import { UserWeekendPresent } from "../../../entity";
import { useRequest, useSetState, useTitle } from "ahooks";
import { getList } from "../../../requests/user-present";
import { PaginationQueryParams } from "../../../request";
import WeekendPresentEntity from "../../../components/_user/_weekend-present/PresentItem";
import svgIcon from "../../../assets/svg";
import { useNavigate } from "react-router-dom";
import { Status } from "../../../utils/enum";

export default function WeekendPresentList(): JSX.Element {
  useTitle("福利兑换");
  const navigate = useNavigate();
  const { StatusEmpty } = svgIcon;
  const [userWeekendPresentList, setUserWeekendWeekendPresentList] = useState<
    UserWeekendPresent[]
  >([]);
  const [finished, setFinished] = useState(false);
  const [queryParams, setQueryParams] = useSetState<PaginationQueryParams>({
    page: 1,
    take: 8,
    status: Status.active,
  });
  const userPresentRequest = useRequest(() => getList(queryParams), {
    manual: true,
    onSuccess: (data) => {
      setUserWeekendWeekendPresentList((v) => {
        const combine = [...v, ...data.data];
        if (combine.length >= data.meta.total) {
          setFinished(true);
        }
        setQueryParams({
          page: queryParams.page + 1,
        });
        return combine;
      });
    },
  });
  return (
    <>
      <div className="p-3 pb-24">
        <List
          loading={userPresentRequest.loading}
          onLoad={userPresentRequest.runAsync}
          finished={finished}
        >
          {userWeekendPresentList.map((userPresent) => (
            <WeekendPresentEntity
              key={userPresent.id}
              userPresent={userPresent}
            />
          ))}
          {!userPresentRequest.loading && userWeekendPresentList.length < 1 && (
            <Empty
              description="空空如也，没有找到可领取的福利"
              image={<Image src={StatusEmpty} />}
            />
          )}
        </List>
      </div>
      <Tabbar border>
        <Tabbar.Item onClick={() => navigate("/cdkey")}>
          <Typography.Text size={"lg"}>兑换码</Typography.Text>
        </Tabbar.Item>
        <Tabbar.Item onClick={() => navigate("/user/weekend-presents/record")}>
          <Typography.Text size={"lg"}>历史</Typography.Text>
        </Tabbar.Item>
      </Tabbar>
    </>
  );
}
